import React from "react";

import GamesIcon from "../assets/images/footer-icon/foot-games.svg";
import GamesIconActive from "../assets/images/footer-icon/foot-games-ac.svg";
import SportsIcon from "../assets/images/footer-icon/foot-sports.svg";
import SportsIconActive from "../assets/images/footer-icon/foot-sports-ac.svg";
import HomeIcon from "../assets/images/footer-icon/home-icon.png";
import BetbyIcon from "../assets/images/footer-icon/foot-betby.png";
import BetbyIconActive from "../assets/images/footer-icon/foot-betby-ac.png";
import CashierIcon from "../assets/images/footer-icon/foot-cashier.svg";
import CashierIconActive from "../assets/images/footer-icon/foot-cashier-ac.svg";
import ProfileIcon from "../assets/images/footer-icon/foot-profile.svg";
import ProfileIconActive from "../assets/images/footer-icon/foot-profile-ac.svg";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

export default function BottomNav() {
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showModals, setShowModals] = useState(false);

  const footerPages = [
    "home",
    "sportsbook",
    "betby",
    "evolution",
    "ezugi",
    "deposit",
    "cashier",
    "support",
    "all-game",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleRedirection = (url) => {
    if (isAuth) {
      setShowModals(false);
      navigate(url);
    } else {
      setShowModals(true);
    }
  };
  return (
    <>
      <footer className="footer d-md-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 ">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li onClick={() => handleRedirection("/casino/evolution")}>
              <a
                className={activePage === "evolution" ? "active" : ""}
                // href="/casino/evolution"
              >
                <img
                  src={GamesIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={GamesIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "sportsbook" ? "active" : ""}
                href={isAuth ? "/sportsbook/Inplay" : "/sportsbook"}
              >
                <img
                  src={SportsIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={SportsIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Sportsbook</span>
              </a>
            </li>
            <li className="home">
              <a className="active" href="/sports">
                <img src={HomeIcon} alt="home" />
                {/* <span>Home</span> */}
              </a>
            </li>
            <li onClick={() => handleRedirection("/casino/ezugi")}>
              <a
                className={activePage === "ezugi" ? "active" : ""}
                // href="/casino/ezugi"
              >
                <img
                  src={GamesIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={GamesIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Casino</span>
              </a>
            </li>
            {/* <li onClick={() => handleRedirection("/betby")}>
              <a
                className={activePage === "betby" ? "active" : ""}
                // href="/betby"
              >
                <img
                  src={BetbyIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={BetbyIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Sports</span>
              </a>
            </li> */}
            {!isAuth && (
              <li>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img
                    src={ProfileIcon}
                    alt="Foot Nav Icon"
                    className="no_active"
                  />
                  <img
                    src={ProfileIconActive}
                    alt="Foot Nav Icon"
                    className="active"
                  />
                  <span>Support</span>
                </a>
              </li>
            )}
            {isAuth && (
              <li>
                <a href="/gatewaylist">
                  <img
                    src={CashierIcon}
                    alt="Foot Nav Icon"
                    className="no_active"
                  />
                  <img
                    src={CashierIconActive}
                    alt="Foot Nav Icon"
                    className="active"
                  />
                  <span>Deposit</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </footer>
      {showModals && (
        <ModalWrapper
          defaultShow={APP_CONST.AUTH_MODAL.LOGIN}
          handleClose={setShowModals}
        />
      )}
    </>
  );
}
