import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
// import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";
import kabaddiIcon from "./../../assets/images/games-icon/kabaddi-icon.png";
import TrophyIcon from "./../../assets/images/games-icon/trophy-icon.png";
import WhatsaPPImg from "./../../assets/images/icons/whatsapp-icon.svg";
import TelegramImg from "./../../assets/images/icons/telegram-icon.svg";
import InstagramImg from "./../../assets/images/icons/instagram-icon.svg";
import ModalWrapper from "../../containers/ModalWrapper";
import { APP_CONST } from "../../config/const";

const LeftBarSports = () => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  const handleRedirection = (url, state) => {
    if (isAuth) {
      navigate(url, {
        state: state ? { selectedTab: state } : "",
      });
      setShowAuthModal(false);
    } else {
      setShowAuthModal(true);
    }
  };
  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul className="games_link">
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Cricket", {
                //   state: { selectedTab: "2" },
                // });
                handleRedirection("/sportsbook/Cricket", "2");
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Soccer", {
                //   state: { selectedTab: "3" },
                // });
                handleRedirection("/sportsbook/Soccer", "3");
              }}
            >
              <img src={SoccerIcon} alt="football" />
              <span>Football</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Tennis", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Tennis", "4");
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Greyhound%20Racing");
                handleRedirection("/sportsbook/Greyhound%20Racing");
              }}
            >
              <img src={GreyhoundIcon} alt="greyhound racing" />
              <span>Greyhound Racing</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Horse%20Racing");
                handleRedirection("/sportsbook/Horse%20Racing");
              }}
            >
              <img src={horseracingIcon} alt="horse racing" />
              <span>Horse Racing</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Kabaddi");
                handleRedirection("/sportsbook/Kabaddi");
              }}
            >
              <img src={kabaddiIcon} alt="kabaddi" />
              <span>Kabaddi</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Politics");
                handleRedirection("/sportsbook/Politics");
              }}
            >
              <img src={PoliticsIcon} alt="politics" />
              <span>Politics</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/casino/evolution");
                handleRedirection("/casino/evolution");
              }}
            >
              <img src={CasinoIcon} alt="Casino Icon" />
              <span>Casino</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Inplay", {
                //   state: { selectedTab: "2" },
                // });
                handleRedirection("/sportsbook/Inplay", "2");
              }}
            >
              <img src={TrophyIcon} alt="trophy" />
              <span>Sports book</span>
            </div>
          </li>
        </ul>

        {!isAuth && (
          <ul className="games_link">
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={WhatsaPPImg} alt="whatsapp" />
                <span>Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={TelegramImg} alt="telegram" />
                <span>Telegram</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={InstagramImg} alt="inastagram" />
                <span>Instagram</span>
              </a>
            </li>
          </ul>
        )}
      </div>
      {showAuthModal && (
        <ModalWrapper
          defaultShow={APP_CONST.AUTH_MODAL.LOGIN}
          handleClose={setShowAuthModal}
        />
      )}
    </>
  );
};

export default LeftBarSports;
