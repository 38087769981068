import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";

import EyeIConNew from "../../../assets/svg/eye.png";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => {
          console.log("64");
          handleClose();
        }}
        closeVariant="black"
      ></Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart d-none">
          <img src={LoginLeftPart} className="signin" />
        </div> */}
          <div className="Login-rightPart">
            {/* <Modal.Title>Sign in now!</Modal.Title> */}
            <div className="form-steps">
              <img src={appDetails?.LOGO_URL} alt="Logo" className="mb-2" />

              {/* <p>Welcome to MMPLAY</p> */}
              <Form>
                <Form.Group
                  className="form-group position-relative"
                  controlId="formBasicName"
                >
                  <Form.Control
                    className="mb-2 position-relative"
                    type="text"
                    placeholder="Username*"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className="position-relative"
                    placeholder="Password*"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? (
                      <img src={hide} alt="" />
                    ) : (
                      <img src={EyeIConNew} alt="" />
                    )}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Login
                </Button>

                <div className="d-flex justify-content-end mb-3">
                  <a
                    className="form-link"
                    onClick={() => {
                      handleClose(APP_CONST.MODAL_CLICK_TYPE.LINK);
                      handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                    }}
                  >
                    Forgot Password
                  </a>
                </div>
              </Form>

              {/* <div className="mt-4 form-link">
              <a
                href="#"
                onClick={() => {
                  navigate("/mobile-number");
                }}
              >
                Sign Up Now
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
