import React from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import BottomNav from "../../../../containers/BottomNav";

const sportsbook = () => {
  return (
    <div className="live_casino_page live">
      <Header />

      <main className="main gamePageSection">
        <div className="ezugi_iframe beforelogin-iframe">
          <iframe
            src="https://design.powerplay247.com/auth"
            className="Live_Ezugi_Iframe"
            frameBorder="0"
            style={{
              width: "100%",
              height: "calc(100vh - 129px)",
            }}
            allowFullScreen={true}
          />
        </div>
      </main>

      {/* <BeforeLoginFooter /> */}
      <BottomNav />
    </div>
  );
};

export default sportsbook;
