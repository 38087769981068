import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLeftSideBar } from "../redux/app/actions";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home-icon.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import InplayIcon from "../assets/images/games-icon/inplay-icon.png";
import aviator from "../assets/images/games-icon/aviator.png";
import crossBtn from "../assets/images/icons/close.png";

import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import kabaddiIcon from "../assets/images/games-icon/kabaddi-icon.png";

import TrophyIcon from "../assets/images/games-icon/trophy-icon.png";
import WhatsaPPImg from "../assets/images/icons/whatsapp-icon.svg";
import TelegramImg from "../assets/images/icons/telegram-icon.svg";
import InstagramImg from "../assets/images/icons/instagram-icon.svg";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

const MobLeftbar = ({ setShowAuthModals }) => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);
  const { showLeftSideBar } = useSelector((state) => state.app);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  const handleRedirection = (url, selectedTab) => {
    if (isAuth) {
      setShowAuthModal(false);
      navigate(url, {
        state: { selectedTab },
      });
    } else {
      setShowAuthModals(true);
    }
  };
  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        <div className="d-flex align-items-center justify-content-between px-2">
          <a href="/sports" alt="logo">
            <img
              src={appDetails?.LOGO_URL}
              alt="Logo"
              width={150}
              className="logo"
            />
          </a>
          <div
            className="crossBtn"
            onClick={() => {
              dispatch(handleLeftSideBar(!showLeftSideBar));
            }}
          >
            <img src={crossBtn} alt="crossBtn" />
          </div>
        </div>

        <ul className="games_link">
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Cricket", {
                //   state: { selectedTab: "2" },
                // });
                handleRedirection("/sportsbook/Cricket", "2");
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Soccer", {
                //   state: { selectedTab: "3" },
                // });
                handleRedirection("/sportsbook/Soccer", "3");
              }}
            >
              <img src={SoccerIcon} alt="fotball" />
              <span>Football</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Tennis", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Tennis", "4");
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Horse%20Racing", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Horse%20Racing", "4");
              }}
            >
              <img src={horseracingIcon} alt="horse racing" />
              <span>Horse Racing</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Greyhound%20Racing", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Greyhound%20Racing", "4");
              }}
            >
              <img src={GreyhoundIcon} alt="greyhound racing" />
              <span>Greyhound Racing</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Kabaddi", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Kabaddi", "4");
              }}
            >
              <img src={kabaddiIcon} alt="kabaddi" />
              <span>Kabaddi</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook/Politics", {
                //   state: { selectedTab: "4" },
                // });
                handleRedirection("/sportsbook/Politics", "4");
              }}
            >
              <img src={PoliticsIcon} alt="politics" />
              <span>Politics</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/casino/evolution");
                handleRedirection("/casino/evolution");
              }}
            >
              <img src={CasinoIcon} alt="casino" />
              <span>Casino</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                // navigate("/sportsbook");
                handleRedirection("/sportsbook");
              }}
            >
              <img src={TrophyIcon} alt="casino" />
              <span>Sports book</span>
            </div>
          </li>
        </ul>

        {!isAuth && (
          <ul className="games_link">
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={WhatsaPPImg} alt="whatsapp" />
                <span>Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={TelegramImg} alt="telegram" />
                <span>Telegram</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={InstagramImg} alt="inastagram" />
                <span>Instagram</span>
              </a>
            </li>
          </ul>
        )}
        {showAuthModal && (
          <ModalWrapper defaultShow={APP_CONST.AUTH_MODAL.LOGIN} />
        )}
      </div>
    </div>
  );
};

export default MobLeftbar;
