import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIconnew.png";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/mmplay.png";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import { handleLeftSideBar } from "../redux/app/actions";
import ModalWrapper from "./ModalWrapper";

const Header = () => {
  const handleShow1 = () => dispatch(handleLeftSideBar(!showLeftSideBar));
  const { showLeftSideBar } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const dispatch = useDispatch();
  const [showAuthModals, setShowAuthModals] = useState(false);
  console.log("showAuthModals", showAuthModals);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName = APP_CONST.AUTH_MODAL.LOGIN) => {
    setDefaultModal(modalName);
    setShowAuthModals(true);
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals(false);
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (showLeftSideBar) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [showLeftSideBar]);

  // const handleRedirection = () => {};
  return (
    <>
      <header className="header header_bfrlgn">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIcon} alt="User Menu Icon" width={25} />
                </Button>

                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                <div className="headerRight ms-auto">
                  <ul className="d-none">
                    <li className="">
                      <a
                        class="tg"
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramIcon} alt="Telegram Icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        class="wtp"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="Whatsapp Icon" />
                      </a>
                    </li>
                    <li className="theme-btn">
                      <buton
                        type="button"
                        className="btn"
                        onClick={toggleClass}
                      >
                        <img
                          src={MoonLight}
                          alt="moon"
                          className="dark_theme"
                        />
                        <img src={SunLight} alt="sun" className="light_theme" />
                      </buton>
                    </li>
                  </ul>

                  <div className="me-2">
                    <GamesSearchModal />
                  </div>

                  <Button
                    variant="primary"
                    onClick={() => {
                      handleModalShow();
                    }}
                    className="login_btn me-2"
                  >
                    Login
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                      // handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                    }}
                    className="signup_btn"
                  >
                    Register
                  </Button>
                  {/* <div className="searchbar">
                  <AiOutlineSearch />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-menu">
          <ul className="mx-auto">
            <li>
              <a href="/sportsbook"> Sportsbook</a>
            </li>
            <li onClick={() => handleModalShow()}>
              <a href="javascript:void(0)">Live Dealers</a>
            </li>
            {/* <li onClick={() => handleModalShow()}>
              <a href="javascript:void(0)">IPL</a>
            </li>
            <li onClick={() => handleModalShow()}>
              <a href="javascript:void(0)">Sports</a>
            </li> */}
            <li onClick={() => handleModalShow()}>
              <a href="javascript:void(0)">Game Shows</a>
            </li>
            <li onClick={() => handleModalShow()}>
              <a href="javascript:void(0)">Aviator</a>
            </li>
          </ul>
        </div>
        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
        {/* <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        {showAuthModals.mobileNumber && (
          <MobileNumberModal
            show={showAuthModals.mobileNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.verifyNumber && (
          <MobileNumberVerifyModal
            show={showAuthModals.verifyNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.register && (
          <RegisterModal
            show={showAuthModals.register}
            handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
            handleModalShow={handleModalShow}
          />
        )}

        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
          }
          handleModalShow={handleModalShow}
        /> */}

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Log in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>

      {showLeftSideBar && (
        <MobLeftbar
          className="d-block d-md-none"
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </>
  );
};

export default Header;
